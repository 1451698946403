import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ban1 from "../assets/ban1.jpg";
import ban2 from "../assets/ban2.jpg";
import ban3 from "../assets/ban3.jpg";
import ban4 from "../assets/ban4.jpg";
import ban5 from "../assets/ban5.jpg";
import ban6 from "../assets/ban6.jpg";
import ban7 from "../assets/ban7.jpg";
import img2 from "../assets/img2.jpg"; // The image for the overview section
import Gallery from "./Gallery";
import ReviewsCarousel from "./ReviewsCarousel";
import Doors from "./Doors";
import Catalog from "./Catalog";

const images = [ban1, ban2, ban3, ban4, ban5, ban6, ban7];

const whyChooseUsData = [
  {
    title: "Unique Blend of Music Genres",
    description:
      "Experience a fusion of various music styles tailored to suit your preferences.",
  },
  {
    title: "Perfect for Every Occasion",
    description:
      "Whether it's a wedding, corporate event, or private party, we make it unforgettable.",
  },
  {
    title: "A Stellar Track Record",
    description:
      "With countless successful performances, our reputation speaks for itself.",
  },
  {
    title: "Captivating Stage Presence",
    description:
      "Our performances are dynamic, engaging, and keep the audience on their feet.",
  },
  {
    title: "A Music Experience Like No Other",
    description:
      "We don't just play music; we create an experience that stays with you forever.",
  },
];

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change slide every 6 seconds
    return () => clearInterval(interval);
  }, []);

  const selectSlide = (index) => {
    setCurrentIndex(index);
  };

  const scroll = (direction) => {
    if (direction === "left") {
      scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
    } else {
      scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };
  

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <Carousel
          selectedItem={currentIndex}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          showStatus={false}
          interval={3000}
          transitionTime={800}
          onChange={(index) => setCurrentIndex(index)}
        >
          {images.map((image, index) => (
            <div className="carousel-image" key={index}>
              <img src={image} alt={`Banner ${index + 1}`} />
            </div>
          ))}
        </Carousel>

        {/* Thumbnail Carousel */}
        <div className="slider-thumbnails">
          {images.map((image, index) => (
            <div
              className={`thumbnail ${index === currentIndex ? "active" : ""}`}
              key={index}
              onClick={() => selectSlide(index)}
            >
              <img src={image} alt={`Thumbnail ${index + 1}`} className="thumbnail-image" />
            </div>
          ))}
        </div>
      </section>

      {/* Company Overview Section */}
      <section className="company-overview">
        <h1 className="overview-heading">OUR STORY</h1>

        <div className="overview-content">
          <h2>Looking for the ultimate live music experience?</h2>
          <p>
            The Band Company is here to rock your world with electrifying
            performances across the most popular destinations in India,
            including Lonavala, Alibaug, Karjat, Igatpuri, Mahabaleshwar, Pune,
            Mumbai, and Goa. Known for their versatility and unmatched energy,
            TBC blends Fusion, Retro, Old-Classics, Party Hits, Rock Anthems,
            Pop Melodies, and Soft Music to create a truly unforgettable
            atmosphere at every gig. Whether you're at a cozy hill station, a
            bustling city, or a beach town, TBC’s live performances elevate any
            setting. From high-energy concerts to intimate gatherings, their
            genre-defying sound and captivating stage presence make every
            performance a standout experience.
          </p>
        </div>
        <div className="overview-image">
          <img src={img2} alt="Band performing live" className="overview-image-img" />
        </div>
      </section>

      

      {/* Why Choose Us Section */}
      <section className="why-choose-us-section">
        <h2>Why Choose The Band Company</h2>
        <div className="scroll-wrapper">
          <button className="scroll-btn left" onClick={() => scroll("left")}>
            &#8249;
          </button>
          <div className="scrolling-cards-container" ref={scrollRef}>
            {whyChooseUsData.map((item, index) => (
              <div key={index} className="scrolling-card">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
          <button className="scroll-btn right" onClick={() => scroll("right")}>
            &#8250;
          </button>
        </div>
      </section>
      <div class="live-music-section">
  <div class="live-music-text">
    <h2 class="live-music-heading">
      We Craft Musical Journeys That are Louder, Deeper, Daring, and Unforgettable.
    </h2>
    <div class="live-music-content">
      <p>
        From the rolling hills of <span class="live-music-highlight">Mahabaleshwar</span> to the vibrant streets of
        <span class="live-music-highlight">Mumbai</span>, TBC brings live music experiences that transcend boundaries.
        Their performances are not just concerts—they are journeys through different eras, emotions, and genres.
      </p>
      <p>
        No matter where you are, <span class="live-music-highlight">The Band Company</span> is ready to bring the live
        music magic to you! Whether it’s the peaceful backdrop of <span class="live-music-highlight">Lonavla</span>, the
        sunny shores of <span class="live-music-highlight">Goa</span>, or the vibrant nightlife of
        <span class="live-music-highlight">Mumbai</span>, TBC's performances are guaranteed to light up the stage and
        create a lasting impression.
      </p>
      <p>
        <strong>Book TBC for Your Next Event in Lonavla, Alibaug, Karjat, Igatpuri, Mahabaleshwar, Pune, Mumbai, Goa!</strong>
      </p>
    </div>
    <a href="#contact" class="live-music-button">Contact Us Now</a>
  </div>
  <div class="live-music-image">
    <img src={img2} alt="The Band Company Performance" />
  </div>
</div>


<Doors/>
      <Gallery />
      <Catalog/>
      <div className="review-section" >
        <ReviewsCarousel />
      </div>
      <section className="contact-section">
    <div className="contact-content">
        <h2>Get in Touch</h2>
        <p>
            We’d love to hear from you! Whether you have questions about our performances, want to book a show, or just want to chat about music, our team is here to help. Reach out to us via email or phone, and we’ll get back to you as soon as possible. Let’s create some unforgettable musical moments together!
        </p>
        <div className="contact-methods">
            <p><strong>Address:</strong> 123 Music Street, Melody City</p>
            <p><strong>Email:</strong> contact@yourband.com</p>
            <p><strong>Phone:</strong> (123) 456-7890</p>

            
            <div className="social-links">
                <a href="#" aria-label="Facebook" className="social-icon"><i className="fab fa-facebook"></i></a>
                <a href="#" aria-label="Twitter" className="social-icon"><i className="fab fa-twitter"></i></a>
                <a href="#" aria-label="Instagram" className="social-icon"><i className="fab fa-instagram"></i></a>
                <a href="#" aria-label="YouTube" className="social-icon"><i className="fab fa-youtube"></i></a>
            </div>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345099196!2d144.9537353153184!3d-37.81720997975116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f9f3e33%3A0x5045675218ceed30!2s123%20Music%20Street%2C%20Melody%20City!5e0!3m2!1sen!2sau!4v1634346800447!5m2!1sen!2sau"
                width="100%" 
                height="200" 
                style={{ border: 0 }} 
                allowFullScreen 
                loading="lazy" 
                title="Google Map"
            ></iframe>
        </div>
    </div>

    <form className="contact-form" action="https://formspree.io/f/yourformid" method="POST">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="phone">Phone Number</label>
        <input type="tel" id="phone" name="phone" required />

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="5" required></textarea>

        <button type="submit" className="form-submit-btn">Send Message</button>
    </form>
</section>


      
    </div>
  );
};

export default Home;
