import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Use HashRouter for routing
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TestimonialCarousel from './components/TestimonialCarousel';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import 'font-awesome/css/font-awesome.min.css';
import WhatsappChat from './components/WhatsappChat';
import ScrollToTopButton from './components/ScrollToTopButton';
import Blogs from './components/Blogs';
import ContactUs from './components/ContactUs';
import Services from './components/Services';
import ReviewsCarousel from './components/ReviewsCarousel';
import Gallery from './components/Gallery';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/T&C';
import Location from './components/Location';
import EnquireButton from './components/EnquireButton';
import Disclaimer from './components/Disclaimer';
import Doors from './components/Doors';
import Catalog from './components/Catalog';
import SoloBandPage from './components/BandPage';

function App() {
    return (
        <div className="App">
            <Navbar />
            <WhatsappChat />
            <ScrollToTopButton />
            <EnquireButton />
            <Routes>
                {/* Route for the Home page */}
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />

                
                {/* Route for the AboutUs page */}
                <Route path="/about" element={<AboutUs />} />
                
                {/* Route for the TestimonialCarousel page */}
                <Route path="/testimonials" element={<TestimonialCarousel />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/reviews" element={<ReviewsCarousel />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/t&c" element={<TermsAndConditions />} />
                <Route path="/disclaimer" element={<Disclaimer />} />


                <Route path="/location" element={<Doors />} />
                <Route path="/doors" element={<Doors />} />
                <Route path="/catalog" element={<Catalog />} />
                <Route path="/bandpage" element={<SoloBandPage />} />







                

                {/* Route for Blogs page */}
                <Route path="/blogs" element={<Blogs />} />
                
                {/* Catch-all route for 404 Not Found */}
                <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>
            <Footer />
        </div>
    );
}

const MainApp = () => {
    return (
        <Router>
            <App />
        </Router>
    );
};

export default MainApp;
